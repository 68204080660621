







































































import { StakingViewModel } from "@/modules/staking/viewmodels/staking-viewmodel";
import { Vue, Component, Inject, Ref } from "vue-property-decorator";
import { Observer } from "mobx-vue";

@Observer
@Component({
  components: {},
})
export default class StandardTicketForm extends Vue {
  @Inject() vm!: StakingViewModel;
  @Ref("form") form: any;

  screenWidth = window.innerWidth;

  validate() {
    return this.form.validate();
  }

  changeStandardTicketInputNo(num) {
    if (this.validate()) {
      this.vm.changeStandardTicketInputNo(num);
    }
  }
}
